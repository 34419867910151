import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import './References.css';

const ReferencesTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol>
                <IonLabel className='references-label'>
                  <IonText className='references-header' color='secondary'>
                    REFERENCES
                  </IonText>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>

                <IonRow>
                  <IonCol>
                    <IonText className='references-subheader'>
                      [Pending 1]
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <IonText className='references-subheader'>
                      [Pending 2]
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='references-text'>
                      [Pending 1]
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <IonText className='references-text'>
                      [Pending 2]
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default ReferencesTab;

import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import './WorkExperience.css';

const WorkTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol size='2' className='work-image'>
                <IonImg src={'/assets/images/ConstructionIcon.png'}/>
              </IonCol>
              <IonCol>
                
                <IonRow>
                  <IonCol>
                    <IonText className='education-header' color='secondary'>
                      WORK EXPERIENCE
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      Marks & Spencer | Volunteer Customer Service
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      October 2018
                      <br/>
                      <br/>
                      Volunteer work at an M&S retail store in Thanet during secondary school. During my time there I worked on the shop floor restocking shelves and interacting with customers helping to; answer their questions, locate items in the store, and advise them on where they can find further information should they need it.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      EKC Group - Broadstairs College | Volunteer Tutoring
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      January 2020 - March 2020
                      <br/>
                      <br/>
                      I helped teach students on level 1 Computing for 3 months during my time at College. When helping to teach the Level 1's, I taught a variety of topics including: Databases, Computer Setups, and simple VB programming.
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default WorkTab;

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './Home.css';

import Toolbar from '../components/toolbar/Toolbar';
import NameTab from '../components/home/Name';
import ProfileTab from '../components/home/Profile';
import EducationTab from '../components/home/Education';
import WorkTab from '../components/home/WorkExperience';
import SkillsTab from '../components/home/Skills';
import HobbiesTab from '../components/home/Hobbies';
import ReferencesTab from '../components/home/References';

const Home: React.FC = () => {

  return (
    <IonPage id="home-page">
      <IonHeader>
        <Toolbar
          pageTitle='Profile'
        />
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Alex Ward
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {
          /*
            Start of page content
          */
        }

        <IonGrid fixed={true}>
          {/* <IonRow>
            <IonCol className='home-image'>
              <IonImg className='home-portrate' src="/assets/images/Portrate.png" />
            </IonCol>
          </IonRow> */}
          <IonRow>
            <IonCol>

              <NameTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <ProfileTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <EducationTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <WorkTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <SkillsTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <HobbiesTab/>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <ReferencesTab/>

            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Home;

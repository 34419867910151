import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import './Profile.css';

const ProfileTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol size='2' className='education-image'>
                <IonImg src="/assets/images/Portrate.png" />
              </IonCol>
              <IonCol>

                <IonRow>
                  <IonCol>
                    <IonLabel className='profile-label'>
                      <IonText className='profile-header' color='secondary'>
                        PROFILE
                      </IonText>
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='profile-subheader'>
                      About Me
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='profile-text'>
                      I have a real passion for Computing, that started during my formative years, and has grown rapidly since, leading me to pursue a career in the Computing Industry.
                      I have developed a particular interest in software engineering and specifically web development.
                      I always display a thorough, conscientious attitude, to my practice, and consistently study hard to make sure that I regularly build on my skills and knowledge.
                    </IonText>
                  </IonCol>
                </IonRow>
                
              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default ProfileTab;

import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
  IonTitle,
} from '@ionic/react';
import './Name.css';
import { logoFacebook, logoGithub, logoLinkedin } from 'ionicons/icons';

const NameTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>
            
            <IonRow>
              <IonCol>
                <IonLabel className='name-label'>
                  <IonText className='name-header' color='secondary'>
                    ALEX WARD
                  </IonText>
                  <br/><br/>
                  <IonText className='name-subheader'>
                    Student at Canterbury Christ Church University
                  </IonText>
                  <br/><br/>
                  <IonText className='name-subheader' color='secondary'>
                    Social Links:
                  </IonText>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonItem button detail={false} lines='none' className='name-label' href='https://www.facebook.com/profile.php?id=100013038154232'>
                  <IonIcon src={logoFacebook} color='tertiary'/>
                  <IonTitle className='name-subheader' color='tertiary'>
                    Facebook
                  </IonTitle>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem button detail={false} lines='none' className='name-label' href='https://github.com/AJGamesArchive'>
                  <IonIcon src={logoGithub} color='tertiary'/>
                  <IonTitle className='name-subheader' color='tertiary'>
                    GitHub
                  </IonTitle>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem button detail={false} lines='none' className='name-label' href='https://www.linkedin.com/in/alex-ward-219599275?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BiQtZBbmrTOOEjZJfyAToPA%3D%3D'>
                  <IonIcon src={logoLinkedin} color='tertiary'/>
                  <IonTitle className='name-subheader' color='tertiary'>
                    LinkedIn
                  </IonTitle>
                </IonItem>
              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default NameTab;

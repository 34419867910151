import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './Contact.css';

import Toolbar from '../components/toolbar/Toolbar';
import ContactInfo from '../components/contact/ContactInfo';

const ContactMe: React.FC = () => {

  return (
    <IonPage id="contact-page">
      <IonHeader>
        <Toolbar
          pageTitle='Contact Me'
        />
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Alex Ward
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {
          /*
            Start of page content
          */
        }

        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>

              <IonItem lines='none'>
                <IonLabel className='contact-label'>
                  <IonText color='secondary' className='contact-header'>
                    Automatic Contact Form
                  </IonText>
                  <br/><br/>
                  <IonText className='contact-subheader'>
                    Coming Soon...
                  </IonText>
                </IonLabel>
              </IonItem>

            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>

              <ContactInfo/>

            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default ContactMe;

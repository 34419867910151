import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonText,
} from '@ionic/react';
import './ContactInfo.css';
import { logoLinkedin, mail } from 'ionicons/icons';

const ContactInfo: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol>
                <IonLabel className='contact-info-label'>
                  <IonText className='contact-info-header' color='secondary'>
                    Manual Contact Info
                  </IonText>
                </IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>

                <IonRow>
                  <IonCol>
                    <IonText className='contact-info-subheader'>
                      <IonIcon src={mail}/> Contact Via Email:
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <IonText className='contact-info-subheader'>
                    <IonIcon src={logoLinkedin}/> Contact Via LinkedIn:
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='contact-info-text'>
                      mail@alexward.xyz
                    </IonText>
                  </IonCol>
                  <IonCol>
                    <IonText className='contact-info-text'>
                      Contact me through my LinkedIn profile <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/alex-ward-219599275?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BiQtZBbmrTOOEjZJfyAToPA%3D%3D">here</a>!
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default ContactInfo;

import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import './Skills.css';

const SkillsTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol>
                
                <IonRow>
                  <IonCol>
                    <IonText className='skills-header' color='secondary'>
                      SKILLS
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-subheader'>
                      Time Management & Organization
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-text'>
                      I consider my time management and planning skills to be very good. I always make sure that I organize myself so that I am able to complete my projects and assignments, with time to spare, before deadlines. I am also very good at keeping track of all tasks / jobs that I need to do or meeting I need to attend and I'm always very punctual.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-subheader'>
                      Team / People Skills
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-text'>
                      I was a Student Rep in my last year of college where I attended various meetings and voiced opinions about things that myself and other students felt needed improving around the college. In this role, I had to liaise with a number of students and staff.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-subheader'>
                      Problem Solving
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-text'>
                      I enjoy challenging tasks and enjoy trying to figure out the best way to solve problems whether that be from brain storming ideas or actually putting ideas into practice. I am generally pretty good at looking at situations carefully, working out the best methods of proceeding, and renaming calm in more stressful environments.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-subheader'>
                      Programming / Web Development
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-text'>
                      I am able to code decently well in: Python, C#, Java Script, Type Script, HTML, CSS, & JSX.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-subheader'>
                      Working Off Own Initiative
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='skills-text'>
                      Once I'm confutable with the environment I'm working in, I'm good at working off my own initiative to work out what needs doing and to get it done. I am also very self motivated and always ensure I finish tasks I start whether their same day tasks or tasks that span a longer period of time.
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
              <IonCol size='2' className='skills-image'>
                <IonImg src={'/assets/images/PuzzlePieceIcon.png'}/>
              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default SkillsTab;

import {
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './Portfolio.css';

import Toolbar from '../components/toolbar/Toolbar';

const Portfolio: React.FC = () => {

  return (
    <IonPage id="portfolio-page">
      <IonHeader>
        <Toolbar
          pageTitle='Portfolio'
        />
      </IonHeader>
      <IonContent fullscreen>

        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">
              Alex Ward
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        {
          /*
            Start of page content
          */
        }

        <IonGrid fixed={true}>
          <IonRow>
            <IonCol>

              <IonItem lines='none'>
                <IonLabel className='portfolio-label'>
                  <IonText className='portfolio-subheader'>
                    Page Coming Soon...
                  </IonText>
                </IonLabel>
              </IonItem>

            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>
    </IonPage>
  );
};

export default Portfolio;

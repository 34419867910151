import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { person, mail, folderOpen } from "ionicons/icons"
import './Toolbar.css';

interface ToolbarProps {
  pageTitle: string;
}

const Toolbar: React.FC<ToolbarProps> = ({ pageTitle }) => {

  return (
    <IonToolbar>
      <IonGrid fixed={true}>
        <IonRow>
          <IonCol>

            <IonItem lines="none">
              <IonAvatar>
                <IonImg src="/assets/images/PrideIcon.png" />
              </IonAvatar>
              <IonTitle className='toolbar-header'>
                Alex Ward - <IonText color='secondary'>{pageTitle}</IonText>
              </IonTitle>
              <IonItem button detail={false} lines='none' href='/home'>
                <IonIcon src={person} color='tertiary'/>
                <IonTitle className='toolbar-header' color='tertiary'>
                  Profile
                </IonTitle>
              </IonItem>
              <IonItem button detail={false} lines='none' href='/portfolio'>
                <IonIcon src={folderOpen} color='tertiary'/>
                <IonTitle className='toolbar-header' color='tertiary'>
                  Portfolio
                </IonTitle>
              </IonItem>
              <IonItem button detail={false} lines='none' href='/contact'>
                <IonIcon src={mail} color='tertiary'/>
                <IonTitle className='toolbar-header' color='tertiary'>
                  Contact Me
                </IonTitle>
              </IonItem>
            </IonItem>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default Toolbar;

import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import './Hobbies.css';

const HobbiesTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol size='2' className='work-image'>
                <IonImg src={'/assets/images/GameIcon.png'}/>
              </IonCol>
              <IonCol>
                
                <IonRow>
                  <IonCol>
                    <IonText className='education-header' color='secondary'>
                      HOBBIES AND INTERESTS
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      Programming
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      In my free time, I enjoy programming, specifically web-based programming making mobile and web apps that make little things in my life more convenient.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      Video Games
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      I tend to play a lot of video games in my spare time to help pass the time and generally relax and forget about life for a little bit.
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      The Railway / Trains
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      I'm very interested in trains and the railway generally, with a specific interest in the signaling & safety systems and how it's controlled and managed. I've been a train enthusiast since I was very young and have since always enjoyed riding on trains both to commute and just ride for fun.
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default HobbiesTab;

import {
  IonCol,
  IonGrid,
  IonImg,
  IonItem,
  IonRow,
  IonText,
} from '@ionic/react';
import './Education.css';

const EducationTab: React.FC = () => {

  return (
    <IonItem lines="none" color=''>
      <IonGrid>
        <IonRow>
          <IonCol>

            <IonRow>
              <IonCol>
                
                <IonRow>
                  <IonCol>
                    <IonText className='education-header' color='secondary'>
                      QUALIFICATIONS
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      Laleham Gap Secondary School
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      September 2014 - July 2019
                      <br/>
                      <br/>
                      - I have a GCSE Combined Science | At Grade 5 5 <br/>
                      - I have a GCSE Mathematics | At Grade 5 <br/>
                      - I have a GCSE English Language | At Grade 4
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-subheader'>
                      EKC Group - Broadstairs College
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonText className='education-text'>
                      September 2019 - July 2022
                      <br/>
                      <br/>
                      - I have a BTEC First Extended Certificate in Computing at Level 2 | At Grade DM (Distinction, Merit) <br/>
                      - I have a BTEC Foundation Diploma in Computing at Level 3 | At Grade D (Distinction) <br/>
                      - I have a BTEC Extended Diploma in Computing at Level 3 | At Grade D*D*D* (Triple Distinction Star)
                    </IonText>
                  </IonCol>
                </IonRow>

              </IonCol>
              <IonCol size='2' className='education-image'>
                <IonImg src={'/assets/images/MedalIcon.png'}/>
              </IonCol>
            </IonRow>

          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default EducationTab;
